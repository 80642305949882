// import React from 'react'
// import { useAxiosSWR } from '../../../../../../../axiosInstance';
// import ApexChart from '../../../../Home/common/ApexChart';

// const Manhattan = ({matchId}) => {
//     const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/runByOvers`);
//     if(isLoading){
//         return <div></div>
//     }
//     if(error){
//         return <div>something went wrong </div>
//     }
//     return (
//         <div>
//             <ApexChart data={data} type="bar" />
//         </div>
//     )
// }

// export default Manhattan

import React from "react";
import { useAxiosSWR } from "../../../../../../../axiosInstance";
import ApexChart from "../../../../Home/common/ApexChart";

export const ODI_FORMATES = ["ODI", "Youth ODI","List A", "Women ODI", "Other List A"];
export const T20_FORMATES = ["T20", "T20I", "Women T20", "Youth T20"];

const Manhattan = ({ matchId }) => {
    const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/runByOvers`);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Something went wrong</div>;

    const matchFormat = data?.matchInfo?.match_format || "T10";
    let categories = data?.xaxis?.categories || [];
    let seriesData = data?.series || [];

    // Define tick interval and labeled overs based on match format
    let tickInterval;
    let labeledOvers = [];
    let totalOvers = 10; // Default

    if (matchFormat === "T10") {
        tickInterval = 2;
        labeledOvers = ["2", "4", "6", "8", "10"];
        totalOvers = 10;
    } else if (T20_FORMATES.includes(matchFormat)) {
        tickInterval = 5;
        labeledOvers = ["5", "10", "15", "20"];
        totalOvers = 20;
    } else if (ODI_FORMATES.includes(matchFormat)) {
        tickInterval = 10;
        labeledOvers = ["10", "20", "30", "40", "50"];
        totalOvers = 50;
    }

    // Ensure categories contain all overs
    const allOvers = Array.from({ length: totalOvers }, (_, i) => (i + 1).toString());

    // Fill missing overs with 0 runs
    seriesData = seriesData.map((team) => {
        const newData = allOvers.map((over, index) => {
            const categoryIndex = categories.indexOf(over);
            return categoryIndex !== -1 && team.data[categoryIndex] !== undefined ? team.data[categoryIndex] : null;
        });
        return { ...team, data: newData };
    });

    return (
        <div>
            <ApexChart
                type="bar"
                data={{
                    xaxis: {
                        categories: allOvers,
                        title: { text: "Overs" },
                        labels: {
                            formatter: (value) => (labeledOvers.includes(value) ? value : ""),
                            rotate: 0,
                        },
                    },
                    yaxis: {
                        title: { text: "Runs per Over" },
                    },
                    series: seriesData,
                    dataLabels: {
                        enabled: false
                    },
                }}
                plotOptions={{
                    bar: {
                        horizontal: false,
                        columnWidth: "60%",
                    },
                }}
                tooltip={{
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: (val) => `${val} Runs`,
                    },
                }}
            />
        </div>
    );
};

export default Manhattan;
