import React from 'react';
import { getMobileDetect } from '../../../../constants';
const LazyChart = React.lazy(() => import('react-apexcharts'));

const ApexChart = ({ data, type, plotOptions }) => {

    const md = getMobileDetect();
    const isMobile = Boolean(md.mobile())

    const options = {
        xaxis: data?.xaxis || { categories: [] },
        yaxis: data?.yaxis || {},
        annotations: data?.annotations || {},
        colors: ['#3A32D1', '#889BB0'],
        legend: {
            position: 'top',
            horizontalAlign: 'right'
        },
        dataLabels: data?.dataLabels || {},
        chart: {
            toolbar: {
                show: false,
            },
        },
        stroke: data?.stroke || {},
        tooltip: { enabled: false },
    };
    if (plotOptions) {
        options['plotOptions'] = plotOptions;
    }
    const series = data?.series || [];

    const searchParams = new URLSearchParams(window.location.search);
    const height = searchParams.get("chartHeight") || "auto";

    if (!data)
        return '';
    else
        return (
            <React.Suspense fallback={<button className="bg-[#ECECEC] rounded-xl py-3"></button>}>
                <LazyChart height={isMobile ? '325px' : 'auto'} options={options} series={series} type={type} />
            </React.Suspense>
        );

};
export default ApexChart;
