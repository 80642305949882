import React from 'react'
import { useAxiosSWR } from '../../../../../../../axiosInstance';
import ApexChart from '../../../../Home/common/ApexChart';

const Runs = ({matchId}) => {
    const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/runByGroups`);
    if(isLoading){
        return <div></div>
    }
    if(error){
        return <div>something went wrong </div>
    }
    return (
        <div>
            <ApexChart data={data} type="bar" plotOptions={{ bar: { horizontal: true } }}/>
        </div>
    )
}

export default Runs
