// import React from 'react'
// import { useAxiosSWR } from '../../../../../../../axiosInstance';
// import ApexChart from '../../../../Home/common/ApexChart';

// const Wickets = ({ matchId }) => {
//     const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/wickets`);
//     if (isLoading) {
//         return <div></div>
//     }
//     if (error) {
//         return <div>something went wrong </div>
//     }
//     return (
//         <div>
//             <ApexChart
//                 data={data}
//                 type="bar"
//                 plotOptions={{ bar: { horizontal: true } }}
//                 xaxis={{
//                     labels: {
//                         formatter: (value) => Math.floor(value),
//                     },
//                 }}
//             />
//         </div>
//     )
// }

// export default Wickets

import React from 'react';
import { useAxiosSWR } from '../../../../../../../axiosInstance';
import ApexChart from '../../../../Home/common/ApexChart';

const Wickets = ({ matchId }) => {
    const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/wickets`);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Something went wrong</div>;

    let categories = data?.xaxis?.categories || [];
    let seriesData = data?.series || [];

    return (
        <div>
            <ApexChart
                type="bar"
                plotOptions={{ bar: { horizontal: true } }}
                data={{
                    xaxis: {
                        categories: categories,
                    },
                    yaxis: {
                        min: 0,
                        max: 10,
                        tickAmount: 10,
                    },
                    series: seriesData,
                }}
            />
        </div>
    );
};

export default Wickets;
