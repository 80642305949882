// import React from 'react'
// import { useAxiosSWR } from '../../../../../../../axiosInstance';
// import ApexChart from '../../../../Home/common/ApexChart';

// const RunRates = ({matchId}) => {
//     const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/runRate`);
//     if(isLoading){
//         return <div></div>
//     }
//     if(error){
//         return <div>something went wrong </div>
//     }
//     return (
//         <div>
//             <ApexChart data={data} type="line" />
//         </div>
//     )
// }

// export default RunRates

// import React from "react";
// import { useAxiosSWR } from "../../../../../../../axiosInstance";
// import ApexChart from "../../../../Home/common/ApexChart";

// const RunRates = ({ matchId }) => {
//     const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/runRate`);

//     if (isLoading) return <div>Loading...</div>;
//     if (error) return <div>Something went wrong</div>;

//     const seriesData = data?.series || [];
//     const matchFormat = data?.matchInfo?.match_format || "T10";

//     // Define tick interval based on match format
//     let tickInterval;
//     let totalOvers;
//     if (matchFormat === "T10") {
//         tickInterval = 2;
//         totalOvers = 10;
//     } else if (["T20", "T20I"].includes(matchFormat)) {
//         tickInterval = 5;
//         totalOvers = 20;
//     } else if (["ODI", "List A"].includes(matchFormat)) {
//         tickInterval = 10;
//         totalOvers = 50;
//     } else {
//         tickInterval = 2;
//         totalOvers = 10;
//     }

//     return (
//         <div>
//             <ApexChart
//                 type="line"
//                 data={{
//                     xaxis: {
//                         tickAmount: Math.floor(totalOvers / tickInterval),
//                         title: { text: "Overs" },
//                     },
//                     yaxis: {
//                         title: { text: "Runs Rate" },
//                     },
//                     series: seriesData,
//                 }}
//                 tooltip={{
//                     shared: true,
//                     intersect: false,
//                     y: {
//                         formatter: (val) => `${val} Runs`,
//                     },
//                 }}
//             />
//         </div>
//     );
// };

// export default RunRates;

import React from "react";
import { useAxiosSWR } from "../../../../../../../axiosInstance";
import ApexChart from "../../../../Home/common/ApexChart";
import { ODI_FORMATES, T20_FORMATES } from "./Manhattan";
import { getMobileDetect } from "../../../../../../constants";

const RunRates = ({ matchId }) => {

    const md = getMobileDetect();
    const isMobile = Boolean(md.mobile())

    const { data, error, isLoading } = useAxiosSWR(`v1/matches/${matchId}/chart/runRate`);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Something went wrong</div>;

    const matchFormat = data?.matchInfo?.match_format || "T10";
    let categories = data?.xaxis?.categories || [];
    let seriesData = data?.series || [];

    // Define tick interval and labeled overs based on match format
    let tickInterval;
    let labeledOvers = [];
    let totalOvers = 10; // Default

    if (matchFormat === "T10") {
        tickInterval = 2;
        labeledOvers = ["2", "4", "6", "8", "10"];
        totalOvers = 10;
    } else if (T20_FORMATES.includes(matchFormat)) {
        tickInterval = 5;
        labeledOvers = ["5", "10", "15", "20"];
        totalOvers = 20;
    } else if (ODI_FORMATES.includes(matchFormat)) {
        tickInterval = 10;
        labeledOvers = ["10", "20", "30", "40", "50"];
        totalOvers = 50;
    }

    // Ensure categories contain all overs
    const allOvers = Array.from({ length: totalOvers }, (_, i) => (i + 1).toString());

    seriesData = seriesData.map((team) => {
        const newData = allOvers.map((over, index) => {
            const categoryIndex = categories.indexOf(over);
            // If no data for the current over, return null; otherwise, return the data
            return categoryIndex !== -1 && team.data[categoryIndex] !== undefined ? team.data[categoryIndex] : null;
        });
        return { ...team, data: newData };
    });

    return (
        <div>
            <ApexChart
                type="line"
                data={{
                    xaxis: {
                        categories: allOvers,
                        title: { text: "Overs" },
                        labels: {
                            formatter: (value) => (labeledOvers.includes(value) ? value : ""),
                            rotate: 0,
                        },
                    },
                    yaxis: {
                        title: { text: "Runs Rate" },
                    },
                    series: seriesData,
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: isMobile ? 2 : 4,
                    },
                }}
                tooltip={{
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: (val) => `${val} Runs`,
                    },
                }}
            />
        </div>
    );
};

export default RunRates;
